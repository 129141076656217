var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-stack h-stretch gap-3"},[_c('div',{staticClass:"h-stack h-end"},[(_vm.$store.state.accessLevel > 8)?_c('button',{staticClass:"add",on:{"click":function($event){return _vm.$router.push('/users/customers/add')}}},[_vm._v(" New ")]):_vm._e()]),_c('Table',{attrs:{"items":_vm.customers,"defaultColumn":0,"css":"200px 200px 100px 1fr 80px 1fr","columns":[
      {
        name: 'Name',
        sort: 'alphabet',
        path: ['fullName'],
      },
      {
        name: 'Company',
        sort: 'alphabet',
        path: ['client', 'name'],
      },
      {
        name: 'Phone',
        sort: 'numeric',
        path: ['phoneNumber'],
        formatType: 'phone',
      },
      {
        name: 'Email',
        sort: 'alphabet',
        path: ['email'],
      } ]},on:{"rowSelected":function($event){return _vm.$router.push('/users/detail/' + $event._id)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }